import {Footer, SectionHeading} from '../components';
export const Terms = () => {
	return (
		<main className="overflow-x-hidden">
			<section className="section-container">
				{/* Section Heading */}
				<SectionHeading
					sub="StayOkay Hostels Kisii"
					main="Terms & Conditions"
				/>

				{/* Section Content */}
				<div className="section-content w-full">
					<p>
						The following rules shall apply to both MALE and FEMALE students
						residing in the hostel. Violation of any rule will make the student
						liable to disciplinary action including expulsion from the hostel.
						Every student must remember that the hostel is like home and
						therefore, he/she should behave himself/herself in such a manner as
						to bring credit to him/her and to the hostel and to the community
						around.
					</p>
					<div className="flex flex-col gap-6">
						<ol className="main-list">
							<li className="main-list-item">
								<h6 className="uppercase inline-block">
									1. Admission and Termination
								</h6>
								<ol className="sub-list">
									<li className="sub-list-item">
										<strong>Admission</strong>
										<p>
											Admission into the hostel will only be granted subject to:
										</p>
										<ul>
											<li>
												Full payment of rent and any other charges for the whole
												period of stay.
											</li>
											<li>Satisfactorily filling the booking form</li>
											<li>
												Accepting and signing the rules and regulations document
											</li>
											<li>
												Accommodation in the hostel cannot be claimed as a
												matter of right. Students who disturb peace and violate
												the rules of the hostels will be expelled from the
												hostels.
											</li>
										</ul>
									</li>
									<li className="sub-list-item">
										<strong>Room allocation and interchange</strong>
										<ul>
											<li>Each room will accommodate 4 students.</li>
											<li>Rooms will be allocated on a first come basis.</li>
											<li>
												Change of room may be permitted in exceptional cases,
												provided it is deemed necessary/justified by the
												Resident Warden.
											</li>
											<li>
												Unauthorised occupation of any room or exchange of rooms
												shall be treated as serious violation of disciplinary
												rules of the hostels and residents found guilty of such
												violation of the hostel rules shall be expelled from the
												hostels without any further enquiry.
											</li>
										</ul>
									</li>
									<li className="sub-list-item">
										<strong>Furniture and fixture</strong>
										<ul>
											<li>
												Each resident will be assigned bed, mattress, anti-bug
												mattress cover, reading table, chair and locker.
											</li>
											<li>
												The resident shall not bring any extra furniture or
												other fixture in the room.
											</li>
											<li>
												All furniture and fixture in the rooms must be cared for
												properly.
											</li>
											<li>
												The resident will be required to pay double the original
												cost if any item is found damaged or missing from the
												room due to careless/ negligent handling.
											</li>
											<li>
												The resident will also be required to pay twice the
												price of repair of an item that is found to have been
												willfully damaged or have been damaged on account of
												misuse or unfair wear and tear.
											</li>
											<li>
												Residents are prohibited from interchanging any
												furniture/fixture from one point/location in the hostel
												to another. This act may lead to expulsion from the
												hostel.
											</li>
											<li>
												Theft or damage to hostel assets in the common
												areas/corridors will be recovered from all residents
												involved.
											</li>
										</ul>
									</li>
									<li className="sub-list-item">
										<strong>Safety & Personal belongings</strong>
										<p>
											Each room will have only one key to be used by all the
											occupants of the room. The key must be surrendered to the
											resident warden anytime while leaving the hostel premises,
											and collect it when you are back. This is to enable us
											keep control of who accesses the rooms.
										</p>
										<ul>
											<li>
												Hostel residents will be completely responsible for all
												their possessions including Laptop, Electronic gadgets,
												personal items etc., They should lock their lockers and
												rooms properly when they go out. The hostel management
												will not be responsible for any loss incurred.
											</li>
											<li>
												No one should use the belongings of other students
												without their consent.
											</li>
										</ul>
									</li>
									<li className="sub-list-item">
										<strong>Electrical Restrictions</strong>
										<ul>
											<li>
												Use of all unauthorised electrical appliances such as
												heaters etc., is strictly prohibited and these will be
												confiscated, and the concerned student will be
												penalised. However, usage of computer and printer in the
												hostel room will be allowed.
											</li>
											<li>
												Lights should be switched on only when necessary and
												switched off when not required. Lights must be switched
												off when the students leave their rooms.
											</li>
										</ul>
									</li>
									<li className="sub-list-item">
										<strong>Room checking and housekeeping</strong>
										<ul>
											<li>
												Resident warden and housekeeping staff or any other
												official of the hostel with granted permission may at
												their discretion inspect and perform routine
												housekeeping duties in any room without prior permission
												from the resident.
											</li>
											<li>
												The room of any student in the hostel can be inspected
												at any time by the Resident Warden or any authorised
												member of the hostel staff or any law enforcement
												officer.
											</li>
										</ul>
									</li>
									<li className="sub-list-item">
										<strong>Notice period and expulsion from the hostel</strong>
										<p>
											A resident is expected to occupy the room for the full
											period of payment. In exceptional circumstances, other
											than disciplinary cases 2 days notice is necessary in case
											a resident desires to check out. Any extra day will be
											charged at the recommended daily rate.
										</p>
										<p>
											The following occurrences will lead to expulsion from the
											hostel:
										</p>
										<ul>
											<li>Any wilful disobedience or defiance of authority</li>
											<li>Non-observance of hostel rules</li>
											<li>Causing damage to a person or property</li>
											<li>
												Indulging in anti-social, anti-national or undesirable
												activities
											</li>
										</ul>
										<p>
											No refund of unutilized rent will be refunded in case of
											expulsion due to but not limited to the above reasons.
										</p>
									</li>
									<li className="sub-list-item">
										<strong>Storage of Baggage during vacation</strong>
										<ul>
											<li>
												Residents will be allowed to use the common storage
												facility for limited quantity of 2 bags. The storage
												will be subject to a small fee and will be allowed only
												for reserved rooms.
											</li>
										</ul>
									</li>
								</ol>
							</li>
							<li className="main-list-item">
								<h6 className="uppercase inline-block">
									2. Hostel Timings, Parties and Visitors
								</h6>
								<ol className="sub-list">
									<li className="sub-list-item">
										<strong>Hostel timings</strong>
										<ul>
											<li>
												This hostel has no curfew, however, residents are
												expected to be mindful of others by not causing
												disturbance to sleeping residents in case they come back
												to the hostel late in the night.
											</li>
											<li>
												Habitual late coming will be recorded in the security
												register and the parents /guardian informed of the same.
											</li>
										</ul>
									</li>
									<li className="sub-list-item">
										<strong>Parties/Celebrations</strong>
										<p>
											Residents are allowed to hold parties and celebrations of
											special occasions with prior notice to the resident
											warden.
										</p>
										<ul>
											<li>
												Celebrations should be done at the common area only and
												should be for a limited period of time. There should not
												be any kind of discomfort to other residents.
											</li>
										</ul>
									</li>
									<li className="sub-list-item">
										<strong>Visitors/Guardian/Parents</strong>
										<p>
											All visitors, guardians and parents are allowed to visit
											the residents only in the lobby area on the ground floor.
											No resident shall take his/her visitor, guardian or parent
											to the rooms.
										</p>
										<ul>
											<li>
												However, in special cases, prior permission of resident
												warden must be obtained for a visitor to go up into the
												rooms for a limited period of time.
											</li>
											<li>
												Any hostel resident harbouring any unauthorised
												person(s) will be expelled from the hostel immediately.
											</li>
											<li>
												No parent or guardian of a student is permitted to stay
												in the hostel rooms. Parents are allowed to view the
												room only on admission day, thereafter they should not
												go to the rooms unless on special cases.
											</li>
											<li>
												The hostel area is declared out of bounds to the
												backloggers. Therefore, the stay of any backlogger in
												the hostel will be treated as a case of trespass. The
												hostellers who entertain or permit backlog students to
												stay in their rooms, will also be liable to disciplinary
												action including expulsion from hostels.
											</li>
										</ul>
									</li>
								</ol>
							</li>
							<li className="main-list-item">
								<h6 className="uppercase inline-block">3. General rules</h6>
								<ol className="sub-list">
									<li className="sub-list-item">
										<strong>
											Cleanliness of common areas, rooms and kitchen
										</strong>
										<p>
											The residents shall keep the hostel premises tidy, neat
											and clean at all times
										</p>
										<ul>
											<li>
												You shall not throw trash anywhere except in the
												dustbins or spaces specifically provided for such
												purpose.
											</li>
											<li>
												The residents shall keep the toilet clean all the time
												and use only the provided toilet paper and bins for
												disposal of sanitary wear. No other object shall be
												flushed down the toilet as this may cause blockage.
											</li>
											<li>
												Residents should keep the kitchen clean by washing their
												crockery and utensils using the provided soap and sponge
												immediately after use so as not to fill up the sink,
												thereby inconveniencing other residents.
											</li>
											<li>
												Residents should not hang wet clothes on furniture or in
												the corridors. Such clothes if found maybe confiscated.
											</li>
											<li>
												Residents are prohibited from writing slogans, or any
												writings or obscene drawings on the hostel walls and
												rooms. Heavy penalities will be imposed on
												students/group of students indulging in such writings.
											</li>
											<li>
												Every student residing in the hostel must cook in the
												kitchen only. Cooking in the rooms is forbidden. If
												found, they will be expelled from hostel immediately.
											</li>
										</ul>
									</li>
									<li className="sub-list-item">
										<strong>
											Registration of laptops/phones for internet use
										</strong>
										<ul>
											<li>
												Residents are entitled to free Wi-Fi and internet use,
												however, all laptops and phones must be registered with
												the resident warden so as to be issued with an internet
												access code.
											</li>
										</ul>
									</li>
									<li className="sub-list-item">
										<strong>Hostel staff</strong>
										<ul>
											<li>
												Residents shall treat hostel staff with due courtesy at
												all times.
											</li>
											<li>
												Service of the housekeeping staff shall not be utilized
												for private or personal work.
											</li>
											<li>
												No tips in cash or kind should be given to the staff of
												the Hostel.
											</li>
											<li>
												Residents shall not get involved in arguments or
												otherwise interfere with the duties of the employees of
												the hostel. If there is any complaint or suggestion to
												be made, it shall be made to the resident warden.
											</li>
										</ul>
									</li>
								</ol>
							</li>
							<li className="main-list-item">
								<h6 className="uppercase inline-block">
									4. Strictly prohibited items/activities
								</h6>
								<ul className="sub-list">
									<li>
										Illegal drugs are not permitted in the hostel compound.
										Smoking should be done at designated areas only and care
										should be taken not to inconvenience others.
									</li>
									<li>Trade and Gambling are not allowed.</li>
									<li>
										Weapons and firearms - Keeping of lethal weapons like
										sticks, rods, chains etc. in the hostel rooms is strictly
										prohibited
									</li>
									<li>Political or communal activity</li>
									<li>Misuse of internet and online social media</li>
									<li>Information to the media</li>
									<li>Pets</li>
									<li>
										Playing loud music and videos etc. inside the rooms is
										strictly prohibited as it causes disturbance to the inmates.
										Any such complaint against boarders will be viewed very
										seriously and seizure of the gadgets will be made along with
										imposition of heavy fine.
									</li>
									<li>
										No hostel resident shall take the law into his/her own
										hands. If any of the hostel resident find that other
										students are indulging in any undesirable activity or
										causing inconvenience or physical injury to him/her or to
										the other hostel inmates, he/she should lodge a complaint in
										writing with the warden concerned.
									</li>
									<li>
										The hostel cannot be considered as the hiding place for
										miscreants. The police authorities may enter the campus and
										detain anyone depending upon the severity of the offence.{' '}
									</li>
								</ul>
							</li>
							<li className="main-list-item">
								<h6 className="uppercase inline-block">
									5. Violating of Hostel rules
								</h6>
								<ol className="sub-list">
									<li className="sub-list-item">
										<strong>Disciplinary action</strong>
										<p>
											In addition to penalties, punishment and fine provided in
											these rules, disciplinary action will be taken against any
											resident who violates the set rules. The disciplinary
											actions will include but not limited to:
										</p>
										<ul>
											<li>Recovery of lost or damaged items</li>
											<li>Prosecution with the law enforcers</li>
											<li>Expulsion from the hostel</li>
										</ul>
									</li>
								</ol>
							</li>
							<li className="main-list-item">
								<h6 className="uppercase inline-block">
									6. Suggestions/Complaints and Compliments
								</h6>
								<p>
									The hostel is committed to making your stay as enjoyable as
									possible, therefore we welcome suggestions on how to make it
									better, complaints on what is not working for you and
									compliments on whatever it is that we are doing well. A
									suggestion box will be placed at a strategic position for your
									use.
								</p>
							</li>
						</ol>
					</div>
				</div>
			</section>
			<Footer />
		</main>
	);
};
